<template>
  <div
    v-if="
      siteNotice &&
        !siteNoticeCookie &&
        (timeLeft && siteNotice.useCountdown || !siteNotice.useCountdown)
    "
    :style="siteNotice.backgroundColor ? `background-color: ${siteNotice.backgroundColor};` : 'background-color: #ffffff'"
    class="relative"
    :class="{
      'z-header': !checkoutPage
    }"
  >
    <div
      v-if="siteNotice"
      class="layout-container px-16 md:px-56"
    >
      <div class="py-8 flex relative">
        <DynamicIcon
          v-if="siteNotice.icon"
          :icon-name="siteNotice.icon"
          class="h-16 w-16 mr-6 mt-px sm:mt-1"
          :style="`color: ${siteNotice.iconColor}`"
        />
        <WysiwygWrapper
          v-if="siteNotice.text && !siteNotice.useCountdown"
          :style-config="{ 'wysiwyg wysiwyg--sm-text pr-32 w-full': true }"
          :html="siteNotice.text"
        />
        <WysiwygWrapper
          v-else-if="siteNotice.text && siteNotice.useCountdown && timeLeft"
          :style-config="{ 'wysiwyg wysiwyg--sm-text pr-32 w-full': true }"
          :html="countdown"
        />
        <XMarkIcon
          class="w-16 h-16 absolute right-0 top-10 cursor-pointer"
          :style="siteNotice.iconColor && `color: ${siteNotice.iconColor}`"
          @click="siteNoticeCookie = 'true', $emit('close-notice')"
        />
      </div>
      <div />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { type INotice } from '~/api-types';
import XMarkIcon from '@heroicons/vue/24/outline/esm/XMarkIcon';
import DynamicIcon from '~/components/DynamicIcon.vue';

const countdown = ref('');
const timeLeft = ref(true);
const timer = ref();

const props = defineProps<{
  siteNotice?: INotice,
  checkoutPage?: boolean
}>();

defineEmits<{
  (event: 'close-notice'): void,
}>();

const siteNoticeCookie = useCookie('site-notice-' + props.siteNotice?.id, {
  maxAge: 3600 * 24 * 365,
});

onMounted(()=> {
  if (props.siteNotice && props.siteNotice.useCountdown) {
    timer.value = setInterval(() => { setCountdown(); }, 1000);
  }
});
onBeforeUnmount(()=> {
  timer.value = null;
});

const setCountdown = () => {
  let timeString = '<span class="font-medium">';
  const now = new Date().getTime();
  const end = props.siteNotice ? new Date(props.siteNotice.countdownDate).getTime() : '';
  const distance = end ? end - now : 0;

  if (distance >= 0) {
    // Time calculations for days, hours, minutes and seconds
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    if (days > 0) {
      timeString += `${days.toString()}d `;
    }
    if (hours > 0 || days > 0) {
      timeString += `${hours.toString()}h `;
    }
    timeString += `${minutes.toString()}m `;
    timeString += `${seconds.toString()}s `;
  } else {
    timeLeft.value = false;
    timer.value = null;
  }
  timeString += '</span>';
  if (props.siteNotice && props.siteNotice.text) {
    countdown.value = props.siteNotice?.text?.replace('{timer}', timeString);
  }
};
setCountdown();

</script>
