<template>
  <div class="py-8 sm:py-12 border-b border-border last:border-0">
    <div class="flex">
      <div class="h-64 w-64 sm:h-[72px] sm:w-[72px] flex-shrink-0 rounded flex justify-center items-center bg-grey200 mr-16">
        <GlobalsLinkHelper
          :to="item.url"
          class="block"
        >
          <nuxt-img
            v-if="item.imageUrl"
            provider="norce"
            :src="item.imageUrl"
            preset="standard"
            sizes="sm:380px md:420px"
            class="h-[2.75rem] w-[2.75rem] sm:h-48 sm:w-48 object-fit"
          />
        </GlobalsLinkHelper>
      </div>
      <div class="w-full flex flex-col justify-between py-2">
        <div class="flex justify-between mb-4">
          <div class="text-xsmall-medium sm:text-small-medium pr-12">
            {{ item.name }}
          </div>
          <div>
            <TrashIcon class="h-16 w-16 select-none cursor-pointer" @click="deleteItem" />
          </div>
        </div>
        <div class="text-xsmall smm:text-small-regular mb-4">
          {{ item.packageSize > 1 ? `${item.packageSize}-pack` : '' }}
        </div>
        <div class="flex justify-between items-center">
          <div class="flex border border-border rounded">
            <div class="px-8 border-r border-border cursor-pointer select-none" @click="setQuantity(-1)">
              -
            </div>
            <div class="px-8 pt-4 text-xs">
              {{ displayedQuantity }}
            </div>
            <div class="px-8 border-l border-border cursor-pointer select-none" @click="setQuantity(1)">
              +
            </div>
          </div>
          <div v-if="item.originalPrice" class="text-xsmall-medium sm:text-small-medium">
            {{ formatPrice(item.originalPrice) }}
          </div>
        </div>
      </div>
    </div>
    <GlobalsInformationNotice
      v-if="cartStockConflict && itemOnlineOnly"
      :notice="productResources.productOnlineStockOnly"
      :icon="'information-circle'"
      :slim-style="isCheckout"
      class="leading-xs mb-6 mt-8"
      :class="{
        'bg-orange': !isCheckout
      }"
    />
    <GlobalsInformationNotice
      v-if="itemInStoreOnly"
      :notice="productResources.productInStoreStockOnly + storeNamesString"
      :icon="'information-circle'"
      :slim-style="isCheckout"
      class="leading-xs mb-6 mt-8"
      :class="{
        'bg-orange': !isCheckout
      }"
    />
    <button
      v-if="cartStockConflict"
      class="btn btn--secondary w-full mt-8"
      @click="deleteItem"
    >
      <TrashIcon class="h-16 w-16 select-none cursor-pointer mr-6" />
      {{ productResources.remove }}
    </button>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useCartStore } from '~/store/cart';
import { CartType, type ICartItem, type IVariantAvailability } from '~/api-types';
import TrashIcon from '@heroicons/vue/24/outline/esm/TrashIcon';
import debounce from 'lodash/debounce';
import { useGlobalContentStore } from '~/store/globalContent';
import useFormatPrice from '~/composables/useFormatPrice';
import { usePageContentStore } from '~/store/pageContent';
const { formatPrice } = useFormatPrice();
const globalContentStore = useGlobalContentStore();
const checkoutSettings = useGlobalContentStore().checkoutSettings;
const productResources = globalContentStore.productResources;
const sharedResources = globalContentStore.sharedResources;
const pageStore = usePageContentStore();
const marketSettings = useGlobalContentStore().marketSettings;
const { apiGet } = useApiFetch();
const storeNamesString = ref('');

const props = defineProps<{
  item: ICartItem,
  cartType: CartType,
  cartStockConflict?: boolean,
  deliveryType: string
}>();

const cartStore = useCartStore();

const itemOnlineOnly = computed(() => {
  if (!checkoutSettings.enableClickAndCollect) {
    return false;
  }
  return !props.item.availability.inStore && props.item.availability.online;
});

const itemInStoreOnly = computed(() => {
  if (!checkoutSettings.enableClickAndCollect) {
    return false;
  }
  return props.item.availability.inStore && !props.item.availability.online;
});

const isCheckout = computed(() => {
  return pageStore.pageType.slice(-1)[0] === 'CheckoutPage';
});

const fetchProductAvailability = async() => {
  const inStockStoreNames: string[] = [];

  const query = `product/availability?PartNo=${props.item.partNo}&PricelistId=${props.item.priceListId}&QuantityInCart=${props.item.quantity}&countryCode=${marketSettings.countryCode}&language=${marketSettings.contentLanguage}`;
  const res = await apiGet<[IVariantAvailability]>(query);

  // Get store names where product is in store stock
  if (res) {
    res.forEach(item => {
      item.stores.forEach(store => {
        if (store.availability.inStock && !inStockStoreNames.includes(store.name)) {
          inStockStoreNames.push(store.name);
        }
      });
    });
  }

  // Handle different cases of the number of store names
  if (inStockStoreNames.length === 1) {
    storeNamesString.value = inStockStoreNames[0];
  } else if (inStockStoreNames.length === 2) {
    storeNamesString.value = inStockStoreNames.join(`' ${sharedResources.and} '`);
  } else if (inStockStoreNames.length > 2) {
    storeNamesString.value = inStockStoreNames.slice(0, -1).join(', ') + `, ${sharedResources.and} ` + inStockStoreNames[inStockStoreNames.length - 1];
  }

  storeNamesString.value = ` ${sharedResources.in} ${storeNamesString.value}`;
};

const displayedQuantity = ref(props.item.quantity || 0);

const setQuantity = (modification: number) => {
  if (displayedQuantity.value + modification >= 0) {
    displayedQuantity.value = displayedQuantity.value + modification;
    updateQuantityDebounced(displayedQuantity.value);
  }
};

const updateQuantity = (newQuantity: number) => {
  updateItem(newQuantity);
};

const updateQuantityDebounced = debounce(updateQuantity, 500);

const deleteItem = () => {
  cartStore.updateItem({
    partNo: props.item.partNo,
    lineNo: props.item.lineNo,
    quantity: 0,
    cartType: props.cartType,
    product: props.item,
    deliveryType: props.deliveryType,
  });
};

const updateItem = async(quantity: number) => {
  try {
    await cartStore.updateItem({
      partNo: props.item.partNo,
      lineNo: props.item.lineNo,
      quantity: quantity,
      cartType: props.cartType,
      product: props.item,
      deliveryType: props.deliveryType,
    });
  } catch (e) {
    console.log(e);
  } finally {
    displayedQuantity.value = props.item.quantity;
  }
};

onMounted(async()=> {
  setTimeout(async() => {
    if (itemInStoreOnly.value) {
      await fetchProductAvailability();
    }
  }, 10);
});

</script>
