<template>
  <div
    class="bg-success py-12 text-white relative px-24 md:px-112 text-center w-full text-small-regular z-20"
  >
    {{ translation.testModeInformation }}
    <div
      class="btn btn--sm btn--secondary btn-close text-black w-fit-content mx-auto sm:inline-block sm:ml-12 sm:mt-0 align-middle whitespace-nowrap"
      @click="deactivateTestMode"
    >
      {{ translation.testModeDectivate }}
    </div>
  </div>
</template>
<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
const runtimeConfig = useRuntimeConfig();
const globalContentStore = useGlobalContentStore();
const testModeCookie = useCookie('test-order');
const translation = globalContentStore.sharedResources;

const deactivateTestMode = (async() => {
  testModeCookie.value = false;

  try {
    await $fetch(`${runtimeConfig.public.apiUrl}user/session/testordermode`, {
      method: 'PUT',
      body: {
        'countryCode': 'SE',
        'enabled': false,
      },
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Accept-Language': globalContentStore.getAcceptLanguage,
        'RequestVerificationToken': globalContentStore.getTheAntiForgeryToken,
      },
    });

    emit('set-test-mode', false);
  } catch (e) {
    console.log(e);
  }
});

const emit = defineEmits<{
  (event: 'set-test-mode', value: boolean): void,
}>();
</script>
